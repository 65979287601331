/* flowertokens.css */

@import url("./normalize.css");
@import url("./fonts.css");

:root {
  --color-fg: rgb(24,34,34);
  --color-bg: rgb(252,254,254);
  --color-lo: rgb(52,54,54);
  --margin: 1rem;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: unset;
  padding: unset;
}

html {
  background-color: var(--color-fg);
  color: var(--color-bg);
  font-family:"Nitti Grotesk", monospace;
  font-size: calc(1em + 0.5vmin);
  font-style: normal;
  font-weight: 500;
  height: 100%;
  line-height: 1.4;
  scroll-behavior: smooth;
}

body {
  font-size: 1rem;
  height: 100%;
  opacity: 0;
  animation: init 0.03s ease-in 0.5s 1 forwards;
}

body > div:first-of-type {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 0 calc(var(--margin) * 2);
  min-height: 100%;
}

body > div:first-of-type > * > * {
  margin: 0 auto;
  max-width: 55ch;
  padding-left: var(--margin);
  padding-right: var(--margin);
}

a:link {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
}

a:hover {
  color: inherit;
  text-decoration: underline;
}

a:focus {
  color: inherit;
}

a:active {
  color: inherit;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--color-fg);
  border-color: var(--color-bg);
  border-radius: 4px;
  border-style: solid;
  border-width: calc(var(--margin) * 0.07);
  color: var(--color-bg);
  cursor: pointer;
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  font-size: 0.8rem;
  height: calc(var(--margin) * 1.4);
  padding: 0 calc(var(--margin) * 0.49);
  text-transform: uppercase;
  width: 100%;
}

code {
  background-color: var(--color-lo);
  border-color: var(--color-lo);
  border-radius: 4px;
  border-style: solid;
  border-width: calc(var(--margin) * 0.03);
  font-family:"Nitti Grotesk", monospace;
  font-size: 0.8rem;
  font-weight: normal;
  padding: calc(var(--margin) * 0.1) calc(var(--margin) * 0.2);
  text-transform: uppercase;
}

/*
code, pre, kbd, samp,
code a, pre a, kbd a, samp a {
  font-family: "Roboto Mono", monospace;
  font-weight: normal;
}
*/

em, i {
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: bold;
  line-height: inherit;
  margin: unset;
}

hr {
  border-color: var(--color-bg);
  border-radius: unset;
  border-style: solid;
  border-width: calc(var(--margin) * 0.03);
  margin: calc(var(--margin) * 0.7) 0;
}

strong, b {
  font-weight: bold;
}

ul {
  list-style-type: none;
}

a[rel~="external"] {
  /*
  cursor: ne-resize;
  */
  white-space: nowrap;
}

a[rel~="external"]::after {
  content: "\2197";
  display: inline-block;
  font-size: 0.6rem;
  font-weight: 900;
  vertical-align: super;
}

img {
  object-fit: cover;
  width: 100%
}

/*
img[alt=""], img:not([alt]) {
  border: 3px dashed rgb(255,0,0);
}
*/

figure {
  line-height: 0;
}

footer {
  background-color: var(--color-bg);
  color: var(--color-fg);
  padding-top: calc(var(--margin) * 0.7);
  padding-bottom: calc(var(--margin) * 0.7);
  z-index: 1;
}

footer a {
  font-weight: bold;
}

footer div {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

header {
  background-color: var(--color-bg);
  color: var(--color-fg);
  padding-top: calc(var(--margin) * 0.7);
  padding-bottom: calc(var(--margin) * 0.7);
  position: sticky;
  top: 0;
  z-index: 1;
}

/*
header > a {
  font-weight: bold;
}

header > a > h1 {
  display: inline-block;
}
*/

header > nav {
  display: grid;
  align-items: center;
  grid-gap: var(--margin);
  grid-template-columns: auto 1fr 10ch;
}

header > nav > button {
  background-color: var(--color-bg);
  border-color: var(--color-fg);
  color: var(--color-fg);
}

header > nav > button > * + * {
  margin-left: calc(var(--margin) * 0.35);
}

header > nav > button > :first-child {
  color: rgb(255,96,96);
}

header > nav > button[disabled] {
  border-color: transparent;
  cursor: not-allowed;
}

header > nav > button[disabled] > :first-child {
  color: rgb(96,215,96);
}

main {
  margin-top: calc(var(--margin) * 1.4);
}

main a:link {
  text-decoration: underline;
}

main section > * + * {
  margin-top: calc(var(--margin) * 0.7);
}

main section > * + h2 {
  margin-top: calc(var(--margin) * 2.1);
}

main > :last-child {
  padding-bottom: calc(var(--margin) * 2.8);
}

.loading {
  display: grid;
  place-items: center;
}

.loading > :first-child {
  font-size: 0.8rem;
}

.loading > .spinner {
  border-color: var(--color-bg) transparent var(--color-bg) transparent;
  border-radius: 50%;
  border-style: solid;
  border-width: calc(var(--margin) * 0.1);
  display: inline-block;
  height: 1rem;
  width: 1rem;
  animation: loading 2.4s linear infinite;
}

.wallet ol {
  list-style-position: inside;
}

.wallet ol > li + li {
  margin-top: calc(var(--margin) * 0.7);
}

.wallet ul.tokens li::before {
  content: "\1F331\0020";
}

.wallet ul.tokens > li {
  display: grid;
  align-items: center;
  grid-gap: calc(var(--margin) * 0.35);
  grid-template-columns: auto 1fr 10ch;
}

.wallet ul.tokens > li + li {
  margin-top: calc(var(--margin) * 0.7);
}

.wallet ul.tokens > li:not(:last-of-type) {
  border-bottom-color: var(--color-bg);
  border-bottom-radius: unset;
  border-bottom-style: solid;
  border-bottom-width: calc(var(--margin) * 0.05);
  padding-bottom: calc(var(--margin) * 0.7);
}

.wallet ul.tokens > li > div:first-of-type {
  display: inline-block;
}

.wallet ul.tokens > li > div:first-of-type .dim {
  opacity: 0.5;
}

.wallet ul.tokens > li > a {
  place-self: center;
}

@keyframes init {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* dark mode */
/*
@media (prefers-color-scheme: dark) {
  :root {
    --color-fg: rgb(252,254,254);
    --color-bg: rgb(24,34,34);
    --color-lo: rgb(52,54,54);
  }
}
*/

/* 479px */
@media (max-width: 29.938em) {
  /*
  header {
    grid-column: 1 / 3;
  }
  main {
    grid-column: 1 / 3;
  }
  footer {
    grid-column: 1 / 3;
    align-items: center;
  }
  */
}

/* 480px */
@media (min-width: 30em) {
}

/* 640px */
@media (min-width: 40em) {
  /*
  body {
    padding-left: calc(var(--margin) * 2);
    padding-right: calc(var(--margin) * 2);
  }
  */
}

/* fallback for list-style-type: "<string>"; */
/*
@supports not (list-style-type: "\2698\0020") {
  main ul li::before {
    content: "\2698\0020";
  }
}
*/
