@font-face {
  font-display: block;
  font-family: "Nitti Grotesk";
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/NittiGrotesk-Normal.woff") format("woff");
}

@font-face {
  font-display: block;
  font-family: "Nitti Grotesk";
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/NittiGrotesk-Bold.woff") format("woff");
}
